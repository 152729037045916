import React from 'react';
import { Typography, Divider, Row, Col } from 'antd';
import moment from 'moment';
import useUser from '../../../hooks/useUser';

import './GreetingsTop.scss';

const GreetingsTop = () => {
    const { Title } = Typography;
    const nowDate = moment().format('dddd , LL');
    const { person } = useUser();

    return (
        <>
            <Row className="greetings-top">
                <Col md={12} xs={24} className="greetings-top__left">
                    <Title level={3}>¡Hola {person.name}!</Title>
                </Col>
                <Col md={12} xs={24} className="greetings-top__right">
                    <p>{nowDate}</p>
                </Col>
            </Row>
            <Divider className="top-devider" />
        </>
    );
};

export default GreetingsTop;
