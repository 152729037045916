import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import ClientIcon from '../../../../assets/img/png/clientesIcon.png';
import UserIcon from '../../../../assets/img/png/usersIcon.png';
import WarningIcon from '../../../../assets/img/png/warningIcon.png';

import './Modules.scss';
import { useHistory } from 'react-router';
import useUser from '../../../../hooks/useUser';
import { ROL_ADMIN } from '../../../../utils/constants';

export default function Modules() {
    const { person } = useUser();
    return (
        <div className="modules">
            <Row gutter={[0, 15]}>
                {[ROL_ADMIN].some((r) => person.roles.includes(r)) && (
                    <>
                        <Col
                            span={12}
                            className="modules__container animate__animated  animate__bounceInLeft"
                        >
                            <ButtonAction type="clients" />
                        </Col>
                        <Col
                            span={12}
                            className="modules__container animate__animated  animate__bounceInLeft"
                        >
                            <ButtonAction type="users" />
                        </Col>
                    </>
                )}
                <Col
                    span={12}
                    className="modules__container animate__animated  animate__bounceInLeft"
                >
                    <ButtonAction type="warnings" />
                </Col>
            </Row>
        </div>
    );
}

function ButtonAction(props) {
    const { type } = props;
    const urlImage =
        type === 'clients'
            ? ClientIcon
            : type === 'users'
            ? UserIcon
            : WarningIcon;
    const title =
        type === 'clients'
            ? 'Clientes'
            : type === 'users'
            ? 'Usuarios'
            : 'Alertas';
    const { Title } = Typography;
    const history = useHistory();

    const handleRedirect = () => {
        if (type === 'clients') {
            history.push('/catalogo/clientes');
        } else if (type === 'users') {
            history.push('/catalogo/usuarios');
        } else if (type === 'warnings') {
            history.push('/catalogo/alertas');
        }
    };

    return (
        <Button
            className="modules__button"
            style={{ padding: 10 }}
            onClick={() => handleRedirect()}
        >
            <img width={60} src={urlImage} />
            <Title style={{ color: 'white', marginTop: 10 }} level={5}>
                {title}
            </Title>
        </Button>
    );
}
