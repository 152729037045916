import React, { useState, useEffect } from 'react';
import {
    Col,
    Input,
    Layout,
    Row,
    Typography,
    Checkbox,
    Space,
    notification,
    Button,
} from 'antd';
import moment from 'moment';
import { FileExcelOutlined } from '@ant-design/icons';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import BillingTable from '../../../components/Admin/Billling/BillingTable';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import { getFinantialBillingApi } from '../../../api/finantial';
import { messageError } from '../../../utils/feedbackMessages';
import { getAccessTokenApi, logout } from '../../../api/auth';
import Modal from '../../../components/General/Modal';
import FileProducts from '../../../components/Admin/FileProducts/FileProducts';
import './Billing.scss';
import { ROL_LAYOUT } from '../../../utils/constants';
import useUser from '../../../hooks/useUser';

export default function Billing() {
    const [reloadBills, setReloadBills] = useState(true);
    const [bills, setBills] = useState([]);
    const token = getAccessTokenApi();
    const [loadingData, setLoadingData] = useState(false);
    const date = [moment().subtract(7, 'days'), moment()];
    const [dateRange, setDateRange] = useState(null);
    const [titleFilter, setTitleFilter] = useState('');

    useEffect(() => {
        if (reloadBills) {
            filterDate(date, 'Emisión');
            setReloadBills(false);
        }
    }, []);

    function filterDate(date, dateString) {
        setReloadBills(true);
        // console.log(dateString, 'datestring');
        if (date) {
            // setFilteredInfo({});
            // setSortedInfo({});

            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);
            let filters = '';
            if (dateString === 'Emisión') {
                setTitleFilter('Emisión');
                filters = `?DocumentStartDate=${date2.startDate}&DocumentEndDate=${date2.endDate}`;
            } else if (dateString === 'Vencimiento') {
                setTitleFilter('Vencimiento');
                filters = `?DueStartDate=${date2.startDate}&DueEndDate=${date2.endDate}`;
            }
            setLoadingData(true);
            // console.log(filters, 'filters');
            getFinantialBillingApi(token, filters).then((response) => {
                setLoadingData(false);

                // console.log(response);
                // console.log(response, 'bills');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setBills(response.result);
                    setReloadBills(false);
                }
            });
        }
    }
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop filterDate={filterDate} />

            <div className="billing__container">
                <BillingTable
                    bills={bills}
                    reloadBills={reloadBills}
                    loading={loadingData}
                    dateRange={dateRange}
                    titleFilter={titleFilter}
                />
            </div>
        </Layout>
    );
}

function HeaderTop({ filterDate }) {
    const { Title } = Typography;
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const { person } = useUser();

    const uploadExcel = () => {
        setModalIsVisible(true);
        setTitleModal('Subir Layout- Facturación');
        setContextModal(
            <FileProducts setIsVisible={setModalIsVisible} type="billing" />
        );
    };

    const showModal = (type) => {
        if (type === 'addExcel') {
            uploadExcel();
        }
    };

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Row>
                        <Title level={3}>Facturación</Title>
                    </Row>
                </Col>

                
                <Col xs={20} md={12} className="container-fecha">
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p style={{ marginRight: 5 }}>Fecha de Emisión:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Emisión'}
                        />
                    </Row>
                </Col>
                {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                    <Col xs={20} md={24} className="container-fecha">
                        <Row xs={10} md={12}>
                            <Button
                                onClick={() => showModal('addExcel')}
                                className="biller-btn"
                                icon={<FileExcelOutlined />}
                                style={{ marginBottom: 20 }}
                            >
                                Subir Excel
                            </Button>
                        </Row>
                    </Col>
                )}
                {/* <Col xs={20} md={23} className="container-fecha">
                    <Row xs={10} md={12}>
                        <p style={{ marginRight: 5 }}>Fecha de Vencimiento:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Vencimiento'}
                        />
                    </Row>
                </Col> */}
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                // width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
}
