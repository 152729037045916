import React, { useState } from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { forgotPasswordApi } from '../../../api/user';

export default function PasswordRecoveryForm() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onFinish = async (values) => {
        setLoading(true);

        const result = await forgotPasswordApi(values);
        // console.log(result);

        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Inténtelomas tarde',
            });

            return true;
        }

        if (result.statusCode !== 200) {
            let message =
                'Ocurrió un error, por favor revise que su correo electrónico este correcto.';

            if (result.statusCode === 404) {
                message = result.description;
            }
            setLoading(false);

            notification['error']({
                message,
            });

            return true;
        } else {
            form.resetFields();

            // localStorage.setItem(ACCESS_TOKEN, result.result.jwtToken);

            notification['success']({
                message: result.description,
                description:
                    'Al hacer el proceso de recuperación recibirás un correo con un enlace para realizar el proceso. Por favor revisa tu correo.',
                duration: 4,
            });

            setLoading(false);

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    };

    return (
        <Spin tip="Cargando..." spinning={loading}>
            <Form
                className="login-form"
                name="normal_login"
                initialValues={{ rememberMe: true }}
                onFinish={onFinish}
                requiredMark={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Correo de recuperación"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingresa un correo electrónico',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Correo de recuperación"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-login"
                    >
                        ENVIAR
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-regresar"
                        onClick={() => history.push('/login')}
                    >
                        REGRESAR
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
