import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Row, Space, Typography, notification } from 'antd';
import {
    HomeOutlined,
    PieChartOutlined,
    FileSearchOutlined,
    PhoneOutlined,
    MailOutlined,
    SyncOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined,
    BarChartOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getContactBySectionApi } from '../../../api/sections';
import { useLocation } from 'react-router-dom';
import './MenuSider.scss';
import {
    ROL_ADMIN,
    ROL_MANAGER,
    ROL_OPERACIONES,
    ROL_CUSTOMER,
    ROL_FINANCIAL,
} from '../../../utils/constants';
import { formatPhoneNumber } from '../../../utils/general';
const MenuSider = ({ menuCollapsed, roles }) => {
    const { Sider } = Layout;
    const { Paragraph, Text } = Typography;
    const token = getAccessTokenApi();
    const location = useLocation();
    const [nameContact, setNameContact] = useState('');
    const [phoneContact, setPhoneContact] = useState('');
    const [emailContact, setEmailContact] = useState('');
    useEffect(() => {
        let role = '';
        if (location.pathname === '/operaciones') {
            role = 'Operaciones';
        } else {
            role = 'Financial';
        }
        getContactBySectionApi(token, role).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    setNameContact(response.result.name);
                    setPhoneContact(response.result.phoneNumber);
                    setEmailContact(response.result.email);
                }
                if (response.statusCode === 404) {
                    setNameContact('');
                    setPhoneContact('');
                    setEmailContact('');
                }
            }
        });
    }, [token, location]);

    let selectedKey = location.pathname;
    let splitted = location.pathname.split('/');

    if (splitted.length > 2) {
        selectedKey = '/' + splitted[1];
    }
    return (
        <Sider
            className="admin-sider"
            collapsed={menuCollapsed}
            collapsible
            breakpoint="md"
            collapsedWidth="0"
            width={220}
        >
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[selectedKey]}
                selectedKeys={selectedKey}
            >
                {[
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_CUSTOMER,
                    ROL_FINANCIAL,
                ].some((r) => roles?.includes(r)) && (
                    <Menu.Item key="/">
                        <Link to="/">
                            <HomeOutlined />
                            <span className="nav-text">Inicio</span>
                        </Link>
                    </Menu.Item>
                )}
                {/* <SubMenu
                    key="operaciones"
                    icon={<AppstoreOutlined />}
                    title="Operaciones"
                >
                    <Menu.Item key="/operaciones/entradas">
                        <Link to="/operaciones/entradas">
                            <span className="nav-text">Entradas - Recibos</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/operaciones/salidas">
                        <Link to="/operaciones/salidas">
                            <span className="nav-text">
                                Salidas - Embarques
                            </span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/operaciones/existencias">
                        <Link to="/operaciones/existencias">
                            <span className="nav-text">
                                Existencias disponibles
                            </span>
                        </Link>
                    </Menu.Item>
                </SubMenu> */}
                {[ROL_CUSTOMER, ROL_FINANCIAL].some((r) =>
                    roles?.includes(r)
                ) && (
                    <Menu.Item key="/estado">
                        <Link to="/estado/cuentas">
                            <PieChartOutlined />
                            <span className="nav-text">Estado de Cuenta</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_MANAGER, ROL_FINANCIAL].some((r) =>
                    roles?.includes(r)
                )  && (
                    <Menu.Item key="/clientes">
                        <Link to="/clientes">
                            <UserOutlined />
                            <span className="nav-text">Clientes</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_CUSTOMER, ROL_FINANCIAL].some((r) =>
                    roles?.includes(r)
                ) && (
                    <Menu.Item key="/facturacion">
                        <Link to="/facturacion">
                            <BarChartOutlined />
                            <span className="nav-text">Facturación</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_MANAGER, ROL_OPERACIONES, ROL_CUSTOMER].some((r) =>
                    roles?.includes(r)
                ) && (
                    <Menu.Item key="/operaciones">
                        <Link to="/operaciones">
                            <SyncOutlined />
                            <span className="nav-text">Operaciones</span>
                        </Link>
                    </Menu.Item>
                )}
                {[
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_CUSTOMER,
                    ROL_FINANCIAL,
                ].some((r) => roles?.includes(r)) && (
                    <Menu.Item key="/aclaraciones">
                        <Link to="/aclaraciones">
                            <FileSearchOutlined />
                            <span className="nav-text">Aclaraciones</span>
                        </Link>
                    </Menu.Item>
                )}
                {roles?.includes(ROL_ADMIN) && (
                    <Menu.Item key="/configuracion">
                        <Link to="/configuracion">
                            <SettingOutlined />
                            <span className="nav-text">Configuración</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_ADMIN, ROL_OPERACIONES].some((r) =>
                    roles.includes(r)
                ) && (
                    <Menu.Item key="/catalogo">
                        <Link to="/catalogo">
                            <UnorderedListOutlined />
                            <span className="nav-text">Catálogo</span>
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
            {[ROL_CUSTOMER].some((r) => roles.includes(r)) && (
                <>
                    {(location.pathname === '/facturacion' ||
                        location.pathname === '/operaciones' ||
                        location.pathname === '/estado/cuentas') && (
                        <div className="menu-extra">
                            {phoneContact !== '' && (
                                <div className="menu-info">
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Space size={5} align="baseline">
                                            <PhoneOutlined />
                                            <Paragraph>
                                                Tel. {''}
                                                {formatPhoneNumber(phoneContact)}
                                            </Paragraph>
                                        </Space>
                                    </Row>
                                </div>
                            )}
                            <div className="menu-info">
                                {nameContact !== '' && (
                                    <Row style={{ justifyContent: 'center' }}>
                                        <div style={{ width: 200 }}>
                                            <Space size={5} align="baseline">
                                                <MailOutlined />
                                                <Paragraph>
                                                    Asesor {''}
                                                    {nameContact}
                                                </Paragraph>
                                            </Space>
                                        </div>
                                    </Row>
                                )}
                                <Row
                                    style={{
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div style={{ width: 200 }}>
                                        <Paragraph>{emailContact}</Paragraph>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    )}
                </>
            )}
        </Sider>
    );
};

export default withRouter(MenuSider);
