import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Button,
    Tag,
    Input,
    Space,
    Typography,
    Tooltip,
} from 'antd';
import BalanceForm from '../BalanceForm';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Modal from '../../../General/Modal';
import {
    FileExcelFilled,
    FilePdfFilled,
    SearchOutlined,
    PlusCircleOutlined,
    FileMarkdownOutlined,
    FilePdfOutlined,
    FileOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatMoney } from '../../../../utils/general';
import FileForm from '../FileForm/FileForm';

import './BillingTable.scss';
import {
    ROL_CUSTOMER,
    ROL_FINANCIAL,
    ROL_MANAGER,
    ROL_OPERACIONES,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';

export default function BillingTable({
    bills,
    reloadBills,
    loading,
    dateRange,
    titleFilter,
}) {
    const [billsData, setBillsData] = useState(bills);
    const [billsExport, setbillsExport] = useState(bills);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    let history = useHistory();
    const { person } = useUser();
    const [showAllColumns, setShowAllColumns] = useState(false);

    useEffect(() => {
        setbillsExport(bills);
    }, [bills]);
    useEffect(() => {
        setFilteredInfo({});
    }, [dateRange]);
    useEffect(() => {
        bills.forEach((bill, index) => {
            bill['key'] = index;
        });
        setBillsData(bills);
    }, [bills]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'invoiceRef',
            key: 'invoiceRef',
            render: (invoiceRef) => (
                <p
                // className="link-folio"
                // onClick={() => showModal(`Folio`)}
                >
                    {invoiceRef}
                </p>
            ),
            ...getColumnSearchProps('invoiceRef'),
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Emisión',
            dataIndex: 'invoiceIssueDate',
            key: 'invoiceIssueDate',
            ellipsis: true,
            sorter: (a, b) =>
                moment(a.invoiceIssueDate).unix() -
                moment(b.invoiceIssueDate).unix(),
            render: (invoiceIssueDate) => (
                <>{moment.utc(invoiceIssueDate).local().format('DD/MM/YYYY')}</>
            ),
            width: 150,
        },
        {
            title: 'Fecha Vencimiento',
            dataIndex: 'invoiceDueDate',
            key: 'invoiceDueDate',
            ellipsis: true,
            sorter: (a, b) =>
                moment(a.invoiceDueDate).unix() -
                moment(b.invoiceDueDate).unix(),
            render: (invoiceDueDate) => (
                <>{moment.utc(invoiceDueDate).local().format('DD/MM/YYYY')}</>
            ),
            width: 150,
        },
        {
            title: 'Referencia Cte.',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            ...getColumnSearchProps('purchaseOrder'),
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Estatus',
            dataIndex: 'invoiceStatus',
            key: 'invoiceStatus',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Pagado', value: 'Pagado' },
                { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.invoiceStatus || null,
            onFilter: (value, record) => record.invoiceStatus.includes(value),
            render: (invoiceStatus) => (
                <Tag
                    color={
                        invoiceStatus === 'Vigente'
                            ? 'green'
                            : invoiceStatus === 'Pagado'
                            ? 'orange'
                            : 'red'
                    }
                >
                    {invoiceStatus.toUpperCase()}
                </Tag>
            ),
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
            align: 'center',
            filters: [
                { text: 'MXN', value: 'MXN' },
                { text: 'USD', value: 'USD' },
            ],
            filteredValue: filteredInfo.currencyId || null,
            onFilter: (value, record) => record.currencyId.includes(value),
            width: 120,
        },
        {
            title: 'Total',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            ...getColumnSearchProps('invoiceTotal'),
            render: (total) => `$ ${formatMoney(total)}`,
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Saldo',
            dataIndex: 'balance',
            key: 'balance',
            ...getColumnSearchProps('balance'),
            render: (saldo) => (
                <Tooltip title="Click para ver detalle">
                    <p
                        style={{ marginTop: 15, cursor: 'pointer' }}
                        onClick={() => showModal(`Saldo`)}
                    >{`$ ${formatMoney(saldo)}`}</p>
                </Tooltip>
            ),
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Archivos de facturas',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 200,
            render: (id, invoice) => (
                <>
                    <Tooltip title="Xml">
                        <Tag color={'green'}>
                            <FileMarkdownOutlined
                                onClick={() => showModalFiles('xml', id)}
                            />
                        </Tag>
                    </Tooltip>

                    <Tooltip title="PDF">
                        <Tag color={'red'}>
                            <FilePdfOutlined
                                onClick={() => showModalFiles('pdf', id)}
                            />
                        </Tag>
                    </Tooltip>
                    {invoice.hasAcuse !== false && (
                        <Tooltip title="Archivo adicional">
                            <Tag color={'orange'}>
                                <FileOutlined
                                    onClick={() => showModalFiles('cfdi', id)}
                                />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
            ellipsis: true,
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 150,
            ellipsis: true,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.noteId !== 0;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.noteId !== 0;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.noteId !== 0 && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.noteId}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                                className="pointer"
                            >
                                {invoice.noteId}
                            </Tag>
                        </Tooltip>
                    )}
                    {[
                        // ROL_LAYOUT,
                        // ROL_MANAGER,
                        // ROL_FINANCIAL,
                        // ROL_OPERACIONES,
                        ROL_CUSTOMER,
                    ].some((r) => person.roles.includes(r)) && (
                        <>
                            {!hasOpenNote && (
                                <Tooltip placement="topLeft" title={'Nueva'}>
                                    <Tag
                                        onClick={() =>
                                            history.push(
                                                `/aclaraciones/nueva/${invoice.invoiceRef}`
                                            )
                                        }
                                        color={'green'}
                                        className="pointer"
                                    >
                                        <PlusCircleOutlined />
                                    </Tag>
                                </Tooltip>
                            )}
                        </>
                    )}
                </>
            ),
        },
    ];
    const columnsAll = [
        {
            title: 'ID Cliente',
            dataIndex: 'customerId',
            key: 'customerId',
            render: (customerId) => (
                <p
                // className="link-folio"
                // onClick={() => showModal(`Folio`)}
                >
                    {customerId}
                </p>
            ),
            ...getColumnSearchProps('customerId'),
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Folio',
            dataIndex: 'invoiceRef',
            key: 'invoiceRef',
            render: (invoiceRef) => (
                <p
                // className="link-folio"
                // onClick={() => showModal(`Folio`)}
                >
                    {invoiceRef}
                </p>
            ),
            ...getColumnSearchProps('invoiceRef'),
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Emisión',
            dataIndex: 'invoiceIssueDate',
            key: 'invoiceIssueDate',
            ellipsis: true,
            sorter: (a, b) =>
                moment(a.invoiceIssueDate).unix() -
                moment(b.invoiceIssueDate).unix(),
            render: (invoiceIssueDate) => (
                <>{moment.utc(invoiceIssueDate).local().format('DD/MM/YYYY')}</>
            ),
            width: 150,
        },
        {
            title: 'Fecha Vencimiento',
            dataIndex: 'invoiceDueDate',
            key: 'invoiceDueDate',
            ellipsis: true,
            sorter: (a, b) =>
                moment(a.invoiceDueDate).unix() -
                moment(b.invoiceDueDate).unix(),
            render: (invoiceDueDate) => (
                <>{moment.utc(invoiceDueDate).local().format('DD/MM/YYYY')}</>
            ),
            width: 150,
        },
        {
            title: 'Referencia Cte.',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            ...getColumnSearchProps('purchaseOrder'),
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Estatus',
            dataIndex: 'invoiceStatus',
            key: 'invoiceStatus',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Pagado', value: 'Pagado' },
                { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.invoiceStatus || null,
            onFilter: (value, record) => record.invoiceStatus.includes(value),
            render: (invoiceStatus) => (
                <Tag
                    color={
                        invoiceStatus === 'Vigente'
                            ? 'green'
                            : invoiceStatus === 'Pagado'
                            ? 'orange'
                            : 'red'
                    }
                >
                    {invoiceStatus.toUpperCase()}
                </Tag>
            ),
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
            align: 'center',
            filters: [
                { text: 'MXN', value: 'MXN' },
                { text: 'USD', value: 'USD' },
            ],
            filteredValue: filteredInfo.currencyId || null,
            onFilter: (value, record) => record.currencyId.includes(value),
            width: 120,
        },
        {
            title: 'Total',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            ...getColumnSearchProps('invoiceTotal'),
            render: (total) => `$ ${formatMoney(total)}`,
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Saldo',
            dataIndex: 'balance',
            key: 'balance',
            ...getColumnSearchProps('balance'),
            render: (saldo) => (
                <Tooltip title="Click para ver detalle">
                    <p
                        style={{ marginTop: 15, cursor: 'pointer' }}
                        onClick={() => showModal(`Saldo`)}
                    >{`$ ${formatMoney(saldo)}`}</p>
                </Tooltip>
            ),
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Archivos de facturas',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 200,
            render: (id, invoice) => (
                <>
                    <Tooltip title="Xml">
                        <Tag color={'green'}>
                            <FileMarkdownOutlined
                                onClick={() => showModalFiles('xml', id)}
                            />
                        </Tag>
                    </Tooltip>

                    <Tooltip title="PDF">
                        <Tag color={'red'}>
                            <FilePdfOutlined
                                onClick={() => showModalFiles('pdf', id)}
                            />
                        </Tag>
                    </Tooltip>
                    {invoice.hasAcuse !== false && (
                        <Tooltip title="Archivo adicional">
                            <Tag color={'orange'}>
                                <FileOutlined
                                    onClick={() => showModalFiles('cfdi', id)}
                                />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
            ellipsis: true,
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 150,
            ellipsis: true,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.noteId !== 0;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.noteId !== 0;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.noteId !== 0 && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.noteId}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                                className="pointer"
                            >
                                {invoice.noteId}
                            </Tag>
                        </Tooltip>
                    )}
                    {[
                        // ROL_LAYOUT,
                        // ROL_MANAGER,
                        // ROL_FINANCIAL,
                        // ROL_OPERACIONES,
                        ROL_CUSTOMER,
                    ].some((r) => person.roles.includes(r)) && (
                        <>
                            {!hasOpenNote && (
                                <Tooltip placement="topLeft" title={'Nueva'}>
                                    <Tag
                                        onClick={() =>
                                            history.push(
                                                `/aclaraciones/nueva/${invoice.invoiceRef}`
                                            )
                                        }
                                        color={'green'}
                                        className="pointer"
                                    >
                                        <PlusCircleOutlined />
                                    </Tag>
                                </Tooltip>
                            )}
                        </>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        // console.log(ROL_MANAGER, ROL_FINANCIAL, ROL_SUPPLIER);
        [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
            person.roles.includes(r)
        ) && setShowAllColumns(true);
        // setColumns([newColumn,...columns])
    }, [person.roles]);

    const showModal = (type) => {
        if (type === 'Saldo') {
            setTitleModal('Saldo');
        } else if (type === 'Folio') {
            setTitleModal('Folio');
        }
        setModalIsVisible(true);
        setContextModal(
            <BalanceForm
                setModalIsVisible={setModalIsVisible}
                type={type}
            ></BalanceForm>
        );
    };

    const showModalFiles = (type, reference) => {
        // console.log(reference);
        setModalIsVisible(true);

        if (type === 'xml') {
            setTitleModal('Archivo XML');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
        if (type === 'pdf') {
            setTitleModal('Archivo PDF');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
        if (type === 'cfdi') {
            setTitleModal('Archivo Acuse de recibo');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        setbillsExport(extra.currentDataSource);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };
    const generateExcel = (entries) => {
        const filename = `Facturación${moment().format('L')}.xlsx`;
        let fecha = moment().format('LLL');
        const ws_name = 'Hoja1';
        let wb = {
            SheetNames: [],
            Sheets: {},
        };
        let ws = {};
        let renInicial = 4;

        var mstrTitulo = `Facturación del ${dateRange.startDate} al: ${dateRange.endDate}`;
        // var mstrTitulo = `Facturación por fecha de ${titleFilter} del ${dateRange.startDate} al: ${dateRange.endDate}`;

        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                G1: { v: fecha, t: 's' },

                A3: { v: 'ID Cliente', t: 's' },
                B3: { v: 'Referencia Cte.', t: 's' },
                C3: { v: 'Folio', t: 's' },
                D3: { v: 'Emisión', t: 's' },
                E3: { v: 'Vencimiento', t: 's' },
                F3: { v: 'Estatus', t: 's' },
                G3: { v: 'Moneda', t: 's' },
                H3: { v: 'Total', t: 's' },
                I3: { v: 'Saldo', t: 's' },
            };

            for (let i in entries) {
                const {
                    customerId,
                    purchaseOrder,
                    invoiceRef,
                    invoiceIssueDate,
                    invoiceDueDate,
                    invoiceStatus,
                    currencyId,
                    invoiceTotal,
                    balance,
                } = entries[i];
                ws['A' + renInicial] = {
                    v: customerId,
                    t: 's',
                };
                ws['B' + renInicial] = { v: purchaseOrder, t: 's' };
                ws['C' + renInicial] = { v: invoiceRef, t: 's' };
                ws['D' + renInicial] = {
                    v: moment
                        .utc(invoiceIssueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['E' + renInicial] = {
                    v: moment.utc(invoiceDueDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['F' + renInicial] = { v: invoiceStatus, t: 's' };
                ws['G' + renInicial] = { v: currencyId, t: 's' };
                ws['H' + renInicial] = { v: invoiceTotal, t: 's' };
                ws['I' + renInicial] = { v: balance, t: 's' };
                renInicial++;
            }
        } else {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                G1: { v: fecha, t: 's' },

                A3: { v: 'Referencia Cte.', t: 's' },
                B3: { v: 'Folio', t: 's' },
                C3: { v: 'Emisión', t: 's' },
                D3: { v: 'Vencimiento', t: 's' },
                E3: { v: 'Estatus', t: 's' },
                F3: { v: 'Moneda', t: 's' },
                G3: { v: 'Total', t: 's' },
                H3: { v: 'Saldo', t: 's' },
            };

            for (let i in entries) {
                const {
                    purchaseOrder,
                    invoiceRef,
                    invoiceIssueDate,
                    invoiceDueDate,
                    invoiceStatus,
                    currencyId,
                    invoiceTotal,
                    balance,
                } = entries[i];
                ws['A' + renInicial] = {
                    v: purchaseOrder,
                    t: 's',
                };
                ws['B' + renInicial] = { v: invoiceRef, t: 's' };
                ws['C' + renInicial] = {
                    v: moment
                        .utc(invoiceIssueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['D' + renInicial] = {
                    v: moment.utc(invoiceDueDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['E' + renInicial] = { v: invoiceStatus, t: 's' };
                ws['F' + renInicial] = { v: currencyId, t: 's' };
                ws['G' + renInicial] = { v: invoiceTotal, t: 's' };
                ws['H' + renInicial] = { v: balance, t: 's' };
                renInicial++;
            }
        }

        // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
        // ws['!cols'] = wscols;
        ws['!ref'] = XLSX.utils.encode_range({
            s: { c: 0, r: 0 },
            e: { c: 15, r: renInicial },
        });
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        XLSX.writeFile(wb, filename, { cellStyles: true });
    };

    const exportPdf = (bills, startDate, endDate) => {
        var mstrTitulo = `Facturación del ${dateRange.startDate} al: ${dateRange.endDate}`;
        // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
        //     .unix(startDate)
        //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
        var mstrPdf = `Facturación${moment().format('L')}.pdf`;

        var registros = [];
        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            bills.forEach((element) => {
                registros.push([
                    element.customerId,
                    element.purchaseOrder,
                    element.invoiceRef,
                    moment
                        .utc(element.invoiceIssueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    moment
                        .utc(element.invoiceDueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.invoiceStatus,
                    element.currencyId,
                    '$ ' + formatMoney(element.invoiceTotal),
                    '$ ' + formatMoney(element.balance),
                ]);
            });
        } else {
            bills.forEach((element) => {
                registros.push([
                    element.purchaseOrder,
                    element.invoiceRef,
                    moment
                        .utc(element.invoiceIssueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    moment
                        .utc(element.invoiceDueDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.invoiceStatus,
                    element.currencyId,
                    '$ ' + formatMoney(element.invoiceTotal),
                    '$ ' + formatMoney(element.balance),
                ]);
            });
        }

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 220;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(-10, -10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };
        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            doc.autoTable({
                head: [
                    [
                        'ID Cliente',
                        'Referencia Cte.',
                        'Folio',
                        'Emisión',
                        'Vencimineto',
                        'Estatus',
                        'Moneda',
                        'Total',
                        'Saldo',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        } else {
            doc.autoTable({
                head: [
                    [
                        'Referencia Cte.',
                        'Folio',
                        'Emisión',
                        'Vencimineto',
                        'Estatus',
                        'Moneda',
                        'Total',
                        'Saldo',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        }

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };

    return (
        <>
            {/* <Title level={5} style={{ color: 'gray' }}>
                Filtro por fecha de: {titleFilter}
            </Title> */}
            <Table
                onChange={handleChange}
                columns={showAllColumns ? columnsAll : columns}
                dataSource={billsData}
                scroll={{ x: 1200 }}
                // pagination={{
                //     pageSize: 20,
                // }}
                loading={loading}
            />

            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => exportPdf(billsExport)}
                        disabled={billsExport.length === 0}
                    >
                        Descargar Pdf
                    </Button>
                    <Button
                        icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => generateExcel(billsExport)}
                        disabled={billsExport.length === 0}
                    >
                        Descargar Excel
                    </Button>
                </Col>
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </>
    );
}
