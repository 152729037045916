import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Button,
    notification,
    Spin,
    Modal as ModalAntd,
    Tag,
    Input,
    Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Modal from '../../../../General/Modal';
import AddUserForm from '../AddUserForm';
import EditUserForm from '../EditUserForm';
import { getUsersApi, deleteUserApi } from '../../../../../api/users';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import './UsersTable.scss';
import { useHistory } from 'react-router';
import { formatPhoneNumber, isMobile } from '../../../../../utils/general';

export default function BillingTable() {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(true);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [userSelected, setUserSelected] = useState(undefined);
    const [usersTable, setUsersTable] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 1,
    });
    const { confirm } = ModalAntd;
    let history = useHistory();
    const [showEllipsis, setShowEllipsis] = useState(true);

    useEffect(() => {
        if(isMobile){
            setShowEllipsis(false)
        }
    }, [])

    useEffect(() => {
        if (reloadUsers) {
            const token = getAccessTokenApi();
            const filters = `?portalType=${0}`;
            // const filters = `?pageNumber=${pagination.current}&pageSize=${pagination.pageSize}`;
            getUsersApi(token, filters).then((response) => {
                if (response) {
                    if (response.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    if (response.statusCode === 200) {
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                        });
                        setUsersTable(response.result);
                        setPagination({
                            ...pagination,
                            total: response.totalRecords,
                        });
                    }
                    setReloadUsers(false);
                }
            });
        }
    }, [reloadUsers]);

    const checkUser = (id) => {
        if (userSelected === id) {
            setUserSelected(undefined);
        } else {
            setUserSelected(id);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Selecciona',
            width: isMobile() ? 100: 70,
            dataIndex: 'id',
            key: 'concurrencyStamp',
            ellipsis: true,
            render: (id) => (
                <Checkbox
                    onChange={(val) => checkUser(id)}
                    checked={userSelected === id}
                    className="button-detail"
                ></Checkbox>
            ),
        },
        {
            title: 'ID Usuario',
            width: 100,
            dataIndex: 'shortId',
            key: 'shortId',
            ellipsis: true,
            ...getColumnSearchProps('shortId'),
        },
        {
            title: 'Usuario',
            dataIndex: 'userName',
            key: 'userName',
            width: 160,
            ellipsis: false,
            ...getColumnSearchProps('userName'),
            render: (userName, element) => (
                <>
                    <p> {userName}</p>

                    {element.roles.includes('Financial') && (
                        <Col>
                            <p
                                onClick={() =>
                                    history.push(
                                        `/catalogo/clientes/${element.id}/financial`
                                    )
                                }
                                style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {' '}
                                Ver clientes financieros
                            </p>
                        </Col>
                    )}
                    {element.roles.includes('Operaciones') && (
                        <Col>
                            <p
                                onClick={() =>
                                    history.push(
                                        `/catalogo/clientes/${element.id}/operador`
                                    )
                                }
                                style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                            >
                                {' '}
                                Ver clientes operaciones
                            </p>
                        </Col>
                    )}
                </>
            ),
        },
        {
            title: 'Nombre(s)',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            width: 150,
            ellipsis:  isMobile() ? false : true,
        },
        {
            title: 'Apellido paterno',
            dataIndex: 'lastName',
            key: 'lastName',
            ...getColumnSearchProps('lastName'),
            width: 150,
            ellipsis: isMobile() ? false : true,
        },
        {
            title: 'Apellido materno',
            dataIndex: 'mothersLastName',
            key: 'mothersLastName',
            ...getColumnSearchProps('mothersLastName'),
            width: 150,
            ellipsis: isMobile() ? false : true,
        },
        {
            title: 'Rol',
            dataIndex: 'roles',
            key: 'roles',
            filters: [
                { text: 'Cliente', value: 'Customer' },
                { text: 'Proveedor', value: 'Supplier' },
                { text: 'Admin', value: 'Admin' },
                { text: 'Operaciones', value: 'Operaciones' },
                { text: 'Manager', value: 'Manager' },
                { text: 'Layout', value: 'Layout' },
                { text: 'Financiero', value: 'Financial' },
            ],
            filteredValue: filteredInfo.roles || null,
            width: 95,
            ellipsis:  false,
            onFilter: (value, record) =>
                record.roles.find((item) => item.includes(value)),
            render: (roles) => (
                <>
                    {roles.map((rol) => {
                        let color = '';
                        switch (rol) {
                            case 'Customer':
                                color = 'blue';
                                break;
                            case 'Supplier':
                                color = 'purple';
                                break;
                            case 'Admin':
                                color = 'red';
                                break;

                            case 'Operaciones':
                                color = 'green';
                                break;
                            case 'Manager':
                                color = 'orange';
                                break;
                            case 'Layout':
                                color = 'gold';
                                break;
                            case 'Financial':
                                color = 'yellow';
                                break;

                            default:
                                break;
                        }
                        let name = rol;

                        switch (rol) {
                            case 'Customer':
                                name = 'Cliente';
                                break;
                            case 'Supplier':
                                name = 'Proveedor';
                                break;
                            case 'Financial':
                                name = 'Financiero';
                                break;
                            case 'Manager':
                                name = 'Gerente';
                                break;
                            default:
                                break;
                        }

                        return (
                            <Tag color={color} key={rol} style={{marginBottom: 8}}>
                                {name}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            ...getColumnSearchProps('phoneNumber'),
            width: 105,
            ellipsis: true,
            render: (phoneNumber) => (
                <p>{formatPhoneNumber(phoneNumber)}</p>
            )
        },
        {
            title: 'Correo electrónico',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
            width: 220,
            ellipsis: isMobile() ? false : true,
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
    };

    const showModal = (type) => {
        if (type === 'Add') {
            setModalIsVisible(true);
            setTitleModal('Agregar Usuario');
            setContextModal(
                <AddUserForm
                    setModalIsVisible={setModalIsVisible}
                    setReloadUsers={setReloadUsers}
                ></AddUserForm>
            );
        } else if (type === 'Edit') {
            if (userSelected !== undefined) {
                setModalIsVisible(true);

                setTitleModal('Editar Usuario');
                setContextModal(
                    <EditUserForm
                        setModalIsVisible={setModalIsVisible}
                        setReloadUsers={setReloadUsers}
                        idUser={userSelected}
                    ></EditUserForm>
                );
            } else if (userSelected === undefined) {
                notification['error']({
                    message: 'Debes seleccionar un usuario',
                });
            }
        }
    };

    const handleDeleteUser = (id) => {
        if (userSelected !== undefined) {
            confirm({
                title: 'Eliminar Usuario',
                icon: <ExclamationCircleOutlined />,
                content:
                    '¿Estás seguro que deseas borrar el usuario seleccionado?, no podrá recuperarla después',
                okText: 'Si',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    deleteUser(id);
                },
                onCancel() {},
            });
        } else {
            notification['error']({
                message: 'Debes seleccionar un usuario a eliminar',
            });
        }
    };

    const deleteUser = async (id) => {
        const accessToken = getAccessTokenApi();
        const result = await deleteUserApi(accessToken, id);
        if (result?.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 405) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 404) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 200) {
            notification['success']({
                message: 'Usuario eliminado exitosamente.',
            });
            setUserSelected(undefined);
        }
        setReloadUsers(true);
    };

    const onChangePagination = (page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize });
        setReloadUsers(true);
    };
    return (
        <>
            <Row>
                <Col span={24}>
                    <Button
                        className="btn-download"
                        onClick={() => showModal('Add')}
                    >
                        Agregar
                    </Button>

                    <Button
                        className="btn-download"
                        onClick={() => showModal('Edit')}
                    >
                        Editar
                    </Button>
                    <Button
                        className="btn-download"
                        onClick={() => handleDeleteUser(userSelected)}
                    >
                        Eliminar
                    </Button>
                </Col>
            </Row>
            <Spin spinning={loadingForm} tip="Cargando...">
                <Table
                    onChange={handleChange}
                    columns={columns}
                    dataSource={
                        usersTable?.map((usr) => ({
                            ...usr,
                            shortId: usr.id.split('-')[0],
                        })) || []
                    }
                    scroll={{ x: 1200 }}
                    loading={reloadUsers}
                    // pagination={{ defaultPageSize: 5 }}
                    pagination={{
                        defaultCurrent: pagination.current,
                        total: pagination.total,
                        onChange: onChangePagination,
                    }}
                />
            </Spin>

            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </>
    );
}
