import React, { useState } from 'react';
import { Button, Col, Row, Spin, Form, notification, Divider } from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import TextArea from 'antd/lib/input/TextArea';
import { addClarificationDetailApi } from '../../../../api/clarifications';

const AddCommentForm = ({ setModalIsVisible, idClarification }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const token = getAccessTokenApi();

    const [form] = Form.useForm();
    const sendComment = async (values) => {
        setLoadingForm(true);

        let data = values;
        data['noteId'] = idClarification;
        // console.log(data, 'data');

        const response = await addClarificationDetailApi(token, data);
        // console.log(response, 'response comment');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelomas tarde',
            });
            setLoadingForm(false);
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Aclaración agregada con éxito',
            });
            setModalIsVisible(false);
        } else {
            let messageError = 'Ocurrió un error, Inténtelootra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.status === 400) {
                messageError = response.errors;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Divider style={{ marginTop: 0 }}>Agregar Comentario</Divider>

            <Form
                name="addClarification"
                layout="vertical"
                form={form}
                onFinish={sendComment}
            >
                <Row justify="center">
                    <Col span={24} xs={24} sm={20} md={20} lg={20}>
                        <Form.Item
                            label="Comentarios:"
                            name="Comments"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese una descripción',
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Comentario"
                                maxLength={150}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        {/* <Form.Item> */}
                        <Button
                            htmlType="submit"
                            className="btn-enviar"
                            block
                            style={{ marginLeft: 0 }}
                        >
                            Agregar
                        </Button>
                        {/* </Form.Item> */}
                    </Col>
                </Row>
            </Form>
            {/* <Divider>Subir Archivos</Divider>
            <AddDocumentsForm 
                clarificationId={idClarification}
                setModalIsVisible={setModalIsVisible}
            /> */}
        </Spin>
    );
};

export default AddCommentForm;
