import React from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import UsersTable from '../../../../components/Admin/Catalog/Users/UsersTable/UsersTable';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function Users() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="clients__container">
                <UsersTable />
            </div>
        </Layout>
    );
}

function HeaderTop() {
    const { Title } = Typography;
    let history = useHistory();

    return (
        <div className="clients__container">
            <Row xs={23} className="header-top">
                <Col xs={19} className="header-top__left">
                    <Title level={3}>Usuarios</Title>
                </Col>
                <Col xs={1}>
                    <Button type="link" onClick={() => history.goBack()}>
                        <ArrowLeftOutlined /> Atrás
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
