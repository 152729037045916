import React from 'react';

import { Layout, Row, Col, Typography } from 'antd';
import LogoPlanB from '../../assets/img/png/logo_planb.png';
import LogoEmpresa from '../../assets/img/png/logo_empresa.png';
import PasswordRecoveryForm from '../../components/General/PasswordRecoveryForm/PasswordRecoveryForm';

// import InstallPWA from '../../components/General/InstallPWA';

export default function PasswordRecovery() {
    const { Content } = Layout;
    const { Paragraph, Title } = Typography;

    return (
        <Layout className="login">
            <Content className="login__content" style={{ padding: 20 }}>
                <div className="login__content-banner animate__animated  animate__slideInDown">
                    <Row className="login-container">
                        <Col span={24}>
                            {/* <Avatar size={200} width={100} src={LogoEmpresa} /> */}
                            <img
                                src={LogoEmpresa}
                                alt="logo_empresa"
                                style={{ width: 100 }}
                            />
                            <p
                                style={{
                                    fontWeight: 300,
                                    lineHeight: 1.4,
                                    fontSize: 18,
                                    textAlign: 'center',
                                    marginTop: 20,
                                }}
                            >
                                {' '}
                                Enviaremos la contraseña al correo que tienes
                                registrado
                            </p>
                        </Col>
                    </Row>
                    <PasswordRecoveryForm />
                    <Row className="login-container">
                        <Col>
                            <Paragraph>Desarrollado por:</Paragraph>
                            <img
                                src={LogoPlanB}
                                alt="logo_planB"
                                style={{ width: 100 }}
                            />
                        </Col>
                    </Row>
                </div>

                {/* <InstallPWA login /> */}
            </Content>
        </Layout>
    );
}
