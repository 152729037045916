export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const COMPANY_ID = 'companyId';
export const COMPANY_IMAGE = 'companyImage';
export const COMPANY_NAME = 'companyName';

export const ROL_ADMIN = 'Admin';
export const ROL_MANAGER = 'Manager';
export const ROL_OPERACIONES = 'Operaciones';
// export const ROL_SUPPLIER = 'Supplier';
export const ROL_CUSTOMER = 'Customer';
export const ROL_LAYOUT = 'Layout';
export const ROL_FINANCIAL = 'Financial';

export const ROL_TODOS = [
    ROL_ADMIN,
    // ROL_SUPPLIER,
    ROL_CUSTOMER,
    ROL_FINANCIAL,
    ROL_MANAGER,
    ROL_OPERACIONES,
];
