import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, Button, Spin, Select } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Contact from '../../../../components/Admin/Configuration/Contact';
import { getAccessTokenApi } from '../../../../api/auth';
import { getAllClients } from '../../../../api/clients';

export default function ContactPage() {
    const [reloadCustomers, setReloadCustomers] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customerSelected, setCustomerSelected] = useState('');
    useEffect(() => {
        if (reloadCustomers) {
            const token = getAccessTokenApi();
            getAllClients(token).then((response) => {
                if (response) {
                    let arrayCustomers = response.result.map((c) => ({
                        value: c.id,
                        label: c.name,
                        company: c.company,
                    }));
                    setCustomers(arrayCustomers);
                }
                setReloadCustomers(false);
            });
        }
    }, [reloadCustomers]);

    return (
        <Layout>
            <GreetingsTop />
            <HeaderTop />
            <Spin spinning={reloadCustomers} tip="Cargando...">
                <div className="billing__container">
                    <Row>
                        <p style={{ marginRight: 20 }}>Cliente: </p>
                        <Select
                            style={{ width: 250, marginBottom: 20 }}
                            placeholder="Selecciona una cliente"
                            defaultValue={customers[0]}
                            defaultActiveFirstOption={true}
                            onChange={(e) => setCustomerSelected(e)}
                        >
                            {customers.map((c) => (
                                <Select.Option key={c.value} value={c.id}>
                                    {c.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Row>

                    <Contact idCustomer={customerSelected} />
                </div>
            </Spin>
        </Layout>
    );
}

function HeaderTop() {
    let history = useHistory();
    const { Title } = Typography;
    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={23} className="header-top__left">
                    <Row>
                        <Col xs={20}>
                            <Title level={3}>Contacto</Title>
                        </Col>

                        <Col xs={2}>
                            <Button
                                type="link"
                                onClick={() => history.goBack()}
                            >
                                <ArrowLeftOutlined /> Atrás
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
