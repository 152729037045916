import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Spin, Tag, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { getCustomersFinancialApi } from '../../../../api/financial';
import useUser from '../../../../hooks/useUser';
import { getAllClients } from '../../../../api/clients';
import { ROL_MANAGER } from '../../../../utils/constants';

export default function CustomersTable() {
    const [reloadCustomers, setReloadCustomers] = useState(true);
    const [usersTable, setUsersTable] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const { person } = useUser();

    useEffect(() => {
        if (reloadCustomers) {
            const token = getAccessTokenApi();

            if (person?.roles.includes(ROL_MANAGER)) {
                getAllClients(token).then((response) => {
                    // console.log(response, 'clientes');
                    if (response) {
                        if (response.statusCode === 200) {
                            response.result.forEach((element, index) => {
                                element['key'] = index;
                            });
                            setUsersTable(response.result);
                        }
                    } else {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }

                        notification['error']({
                            message: 'Inténtelomas tarde',
                        });
                    }
                    setReloadCustomers(false);
                });
            } else {
                getCustomersFinancialApi(token, person.idUser).then(
                    (response) => {
                        if (response) {
                            if (response.statusCode === 401) {
                                notification['error']({
                                    message: 'Usuario no autorizado',
                                });

                                setTimeout(() => {
                                    logout();
                                    window.location.reload();
                                }, 1500);
                                return;
                            }
                            if (response.statusCode === 200) {
                                response.result.forEach((element, index) => {
                                    element['key'] = index;
                                });
                                setUsersTable(response.result);
                            }
                            setReloadCustomers(false);
                        }
                    }
                );
            }
        }
    }, [reloadCustomers]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID Cliente',
            width: 150,
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
            render: (id) => id?.split('-')[0],
            ellipsis: true,
        },
        {
            title: 'Nombre(s)',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            width: 250,
            ellipsis: true,
        },
        {
            title: 'RFC',
            dataIndex: 'fiscalID',
            key: 'fiscalID',
            ...getColumnSearchProps('fiscalID'),
            width: 150,
            ellipsis: true,
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
    };

    return (
        <>
            <Spin spinning={loadingForm} tip="Cargando...">
                <Table
                    onChange={handleChange}
                    columns={columns}
                    dataSource={usersTable}
                    loading={reloadCustomers}
                    scroll={{ x: 500 }}
                    pagination={{ defaultPageSize: 5 }}
                />
            </Spin>
        </>
    );
}
