import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Form, Button, Select, notification } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { getUsersApi } from '../../../../../api/users';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { addAlertApi } from '../../../../../api/alerts';
import { getCompanyIdApi } from '../../../../../api/company';

const AddAlertForm = ({ setModalIsVisible, setReloadAlerts }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [form] = Form.useForm();
    const typeAlert = [
        { value: 0, label: 'Operación cambio de status' },
        { value: 1, label: 'Vencimiento de facturas' },
    ];
    const colors = [
        { value: 'rojo', label: 'Rojo' },
        { value: 'amarillo', label: 'Amarillo' },
        { value: 'verde', label: 'Verde' },
    ];
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const profiles = [
        { value: 'Financial', label: 'Financiero' },
        { value: 'Customer', label: 'Cliente' },
    ];

    const addAlertFunction = async (values) => {
        setLoadingForm(true);
        let data = values;
        data['company'] = companyId;
        const response = await addAlertApi(token, data);
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelomas tarde',
            });
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            setModalIsVisible(false);
            notification['success']({
                message: 'Alerta agregada con éxito',
            });
            setLoadingForm(false);
            setReloadAlerts(true);
            return;
        }
        if (response.statusCode === 400) {
            notification['error']({
                message: response.description,
            });
            setLoadingForm(false);
            return;
        } else {
            let messageError = 'Ocurrió un error, Inténtelootra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }

            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Cargando...">
            <Form
                name="add-promo"
                layout="vertical"
                form={form}
                onFinish={addAlertFunction}
            >
                <div>
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            <Form.Item
                                label="Elige el color del identificador de la alerta:"
                                name="pinColor"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione un color',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    options={colors}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Elige el tipo de alerta:"
                                name="alertType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor seleccione un tipo',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    options={typeAlert}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Elige el perfil de destino de la alerta:"
                                name="userProfile"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione un perfil',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    options={profiles}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Activar alerta:"
                                name="active"
                                valuePropName="checked"
                            >
                                <Checkbox></Checkbox>
                            </Form.Item>
                            <Form.Item
                                label="Descripción:"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor agregue descripción de la alerta',
                                    },
                                ]}
                            >
                                <TextArea></TextArea>
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn-enviar"
                                            block
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                    {/* <Button className="btn-cancelar">
                                        Editar
                                    </Button> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default AddAlertForm;
