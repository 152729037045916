import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Typography, Tag, Button } from 'antd';
import moment from 'moment';

import './TableHome.scss';
import { formatMoney } from '../../../utils/general';
import { useHistory } from 'react-router';
import { ROL_FINANCIAL } from '../../../utils/constants';
import useUser from '../../../hooks/useUser';

export default function TableHome({ title, type, data }) {
    const [dataTable, setDataTable] = useState(data);
    let history = useHistory();
    const { person } = useUser();

    useEffect(() => {
        data.forEach((op, index) => {
            op['key'] = index;
        });

        setDataTable(data);
    }, [data]);

    const { Title } = Typography;

    const columnsOperaciones = [
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
            render: (reference, operation) => (
                <>
                    {[ROL_FINANCIAL].some((r) => person.roles.includes(r)) && (
                        <Button
                            // type="primary"
                            className="button-detail"
                            onClick={() =>
                                history.push(
                                    `/operaciones/detalle/${operation.id}`
                                )
                            }
                        >
                            {reference}
                        </Button>
                    )}
                    {![ROL_FINANCIAL].some((r) => person.roles.includes(r)) && (
                        <>{reference}</>
                    )}
                </>
            ),
        },
        {
            title: 'Fecha',
            dataIndex: 'operationDate',
            key: 'operationDate',
            render: (operationDate) => (
                <>{moment.utc(operationDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            render: (tags) => (
                <Tag color={tags === 'En proceso' ? 'orange' : 'green'}>
                    {tags.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Servicio',
            dataIndex: 'service',
            key: 'service',
        },
    ];

    const columnsCuentas = [
        {
            title: 'Referencia',
            dataIndex: 'invoiceRef',
            key: 'invoiceRef',
        },
        {
            title: 'Fecha',
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (issueDate) => (
                <>{moment.utc(issueDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            render: (tags) => (
                <Tag color={tags === 'En proceso' ? 'orange' : 'green'}>
                    {tags.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Monto',
            dataIndex: 'total',
            key: 'total',
            render: (total) => <p>{`$ ${formatMoney(total)}`}</p>,
        },
    ];

    return (
        <div className="table-home">
            <Row>
                <Col span={24} className="table-home__title">
                    <Title level={3} style={{ textTransform: 'uppercase' }}>
                        {title}
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        scroll={{ x: 200 }}
                        columns={
                            type === 'operaciones'
                                ? columnsOperaciones
                                : columnsCuentas
                        }
                        dataSource={
                            type === 'operaciones' ? dataTable : dataTable
                        }
                        pagination={{ position: ['none'] }}
                    />
                </Col>
            </Row>
        </div>
    );
}
