import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    notification,
    Spin,
    Tag,
    Input,
    Space,
    Modal as ModalAntd,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import {
    deleteCustomersFinancialApi,
    getCustomersFinancialApi,
} from '../../../../api/financial';
import { useParams } from 'react-router';
import { messageError } from '../../../../utils/feedbackMessages';
import {
    getCustomersOperationsApi,
    deleteCustomersOperationsApi,
} from '../../../../api/operations';

export default function CustomersAdminTable() {
    const [reloadCustomers, setReloadCustomers] = useState(true);
    const [usersTable, setUsersTable] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    let { iduser, type } = useParams();
    const { confirm } = ModalAntd;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (reloadCustomers) {
            if (type === 'financial') {
                getCustomersFinancialApi(token, iduser).then((response) => {
                    if (response) {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                        if (response.statusCode === 200) {
                            response.result.forEach((element, index) => {
                                element['key'] = index;
                            });
                            setUsersTable(response.result);
                        }
                        setReloadCustomers(false);
                    }
                });
            } else {
                getCustomersOperationsApi(token, iduser).then((response) => {
                    if (response) {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                        if (response.statusCode === 200) {
                            response.result.forEach((element, index) => {
                                element['key'] = index;
                            });
                            setUsersTable(response.result);
                        }
                        setReloadCustomers(false);
                    }
                });
            }
        }
    }, [reloadCustomers, token, iduser, type]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID Cliente',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
            render: (id) => id?.split('-')[0],
        },
        {
            title: 'Nombre(s)',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'RFC',
            dataIndex: 'fiscalID',
            key: 'fiscalID',
            ...getColumnSearchProps('fiscalID'),
        },
        {
            title: 'Eliminar',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => handlerDeleteFinancial(id)}
                        type="primary"
                        size="medium"
                        className="login-form-regresar"
                        style={{ marginLeft: 20 }}
                    />
                </>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
    };
    const handlerDeleteFinancial = (id) => {
        confirm({
            title: 'Eliminar cliente',
            icon: <ExclamationCircleOutlined />,
            content: `¿Estás seguro que desea eliminar el cliente seleccionado?, no se podrá recuperar después`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteFinancial(id);
            },
            onCancel() {},
        });
    };
    const deleteFinancial = async (id) => {
        let result = null;
        if (type === 'financial') {
            result = await deleteCustomersFinancialApi(token, iduser, id);
        } else {
            result = await deleteCustomersOperationsApi(token, iduser, id);
        }
        if (result === undefined) {
            notification['error']({
                message: messageError(),
            });
        } else {
            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);

                return false;
            }

            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Cliente eliminado exitosamente',
                });
                setReloadCustomers(true);
            } else {
                let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                if (
                    result.statusCode === 400 ||
                    result.statusCode === 409 ||
                    result.statusCode === 404
                ) {
                    msg = result.description;
                }
                notification['error']({
                    message: msg,
                });
            }
        }
    };
    return (
        <>
            <Spin spinning={loadingForm} tip="Cargando...">
                <Table
                    onChange={handleChange}
                    columns={columns}
                    dataSource={usersTable}
                    scroll={{ x: 500 }}
                    loading={reloadCustomers}
                    pagination={{ defaultPageSize: 5 }}
                />
            </Spin>
        </>
    );
}
