import { basePath } from './config';

//TABLA DE ACLARACIONES
export function getAllClarificationsApi(token, filters) {
    const url = `${basePath}/notes?portalType=0&${filters}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET DE UNA ACLARACION
export function getClarificationApi(token, id) {
    const url = `${basePath}/notes/${id}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//AGREGAR UNA ACLARACION
export function addClarificationApi(token, data) {
    const url = `${basePath}/notes`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//EDITAR UNA ACLARACION
export function editClarificationApi(token, data, id) {
    const url = `${basePath}/notes/${id}`;
    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//ELIMINAR UNA ACLARACION
export function deleteClarificationApi(token, data, id) {
    const url = `${basePath}/notes/${id}`;
    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//COMENTARIOS DE UNA ACLARACION
export function getClarificationDetailApi(token, id) {
    const url = `${basePath}/notes/${id}/details`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//TRAER TODOS LOS COMENTARIOS A UNA ACLARACION
export function getClarificationDetailsApi(token, id) {
    const url = `${basePath}/note-details/${id}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//AGREGAR COMENTARIO A UNA ACLARACION
export function addClarificationDetailApi(token, data) {
    const url = `${basePath}/note-details`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//EDITAR COMENTARIO DE UNA ACLARACION
export function editClarificationDetailApi(token, data, id) {
    const url = `${basePath}/note-details/${id}`;
    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//ELIMINAR COMENTARIO DE UNA ACLARACION
export function deleteClarificationDetailApi(token, data, id) {
    const url = `${basePath}/note-details/${id}`;
    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//POST DOCUMENT OPERATIONS
export function addDocumentsClarificationApi(token, data) {
    const url = `${basePath}/note-details/attach-files`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//GET DOCUMENTS OPERATIONS
export function getDocumentsInfoClarificationApi(token, data) {
    const url = `${basePath}/Documents/GetDocumentsInfo`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}


