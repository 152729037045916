import { basePath, bingKey } from './config';
import { notification } from 'antd';

export function signUpApi(data) {
    const url = `${basePath}/Account/Register`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            // "Autorization" : "Basic ZmdhcmNpYUBwbGFuYnN5cy5jb206TjRmYXd4RyM0VTNEckNF",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export function signInApi(data) {
    const url = `${basePath}/Account/Login`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getUserApi(token) {
    const url = `${basePath}/Account/Profile`;

    const params = {
        method: 'GET',
        // body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            notification['error']({
                message: 'Ocurrio un error, inténtelo más tarde.',
            });
        });
}

export function forgotPasswordApi(data) {
    const url = `${basePath}/Account/ForgotPassword`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function resetPasswordApi(data) {
    const url = `${basePath}/Account/ResetPassword`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function changePasswordApi(token, data) {
    const url = `${basePath}/Account/ChangePassword`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function changeProfilePictureApi(token, data) {
    const url = `${basePath}/Account/SetProfilePicture`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function changeProfileInfoApi(token, data) {
    const url = `${basePath}/Account/Profile`;
    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getDistanceUser(wayPoint_1, wayPoint_2) {
    const url = `https://dev.virtualearth.net/REST/v1/Routes?wayPoint.1=${wayPoint_1}&wayPoint.2=${wayPoint_2}&distanceUnit=Kilometer&key=${bingKey}`;

    return fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function updateUserApi(token, data) {
    const url = `${basePath}/Administration/EditUser`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getAddressByZipcodeApi(zipcode) {
    // const url = `https://api-sepomex.hckdrk.mx/query/info_cp/${zipcode}?token=${tokenSepoMexApi}`;
    const url = `${basePath}/zip-codes/${zipcode}`;

    return fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getUsersList(token, compnayId, status) {
    const url = `${basePath}/Administration/GetUsersInfo`;
    const data = {
        Company: compnayId,
        Active: status,
    };
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function updateUserAdminApi(token, data) {
    const url = `${basePath}/Administration/EditUserFromAdmin`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function deleteUserApi(token, idUser) {
    const url = `${basePath}/Administration/DeleteUser?id=${idUser}`;

    const params = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function confirmEmailApi(token, email) {
    const url = `${basePath}/Account/ConfirmEmail`;

    const data = {
        email: email,
        token: token,
    };
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function resendEmailConfirmationApi(email, companyId) {
    const url = `${basePath}/Account/ResendEmailConfirmation?email=${email}&companyId=${companyId}`;

    const params = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response;
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function validateReportAccessCodeApi(token, code) {
    const url = `${basePath}/Account/ValidateReportAccessCode/${code}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response;
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getAllUserFiltersApi(token, filters) {
    const url = `${basePath}/Users/GetAll${filters}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getAddressUser(point) {
    const url = `https://dev.virtualearth.net/REST/v1/locationrecog/${point}?key=${bingKey}&includeEntityTypes=address&output=json`;

    return fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function validateReportAccessCodeInactiveApi(token, code) {
    const url = `${basePath}/Account/ValidateReportAccessCode/${code}?isSubscription=${true}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response;
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
