import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Layout,
    Row,
    Typography,
    notification,
    Tooltip,
} from 'antd';
import { FileExcelOutlined, DeleteOutlined } from '@ant-design/icons';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import OperationTable from '../../../components/Admin/Operations/OperationTable';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import useUser from '../../../hooks/useUser';
import { getAccessTokenApi, logout } from '../../../api/auth';
import moment from 'moment';
import { getFinantialBalanceApi } from '../../../api/finantial';
import { formatMoney, messageError } from '../../../utils/general';
import { getAllOperationsApi } from '../../../api/operations';
import FileProducts from '../../../components/Admin/FileProducts/FileProducts';
import Modal from '../../../components/General/Modal';
import { ROL_LAYOUT } from '../../../utils/constants';
import useStatusFilter from '../../../hooks/useStatusFilter';

export default function Operation() {
    const [reloadOperations, setReloadOperations] = useState(true);
    const [operations, setOperations] = useState([]);
    const token = getAccessTokenApi();
    const { person } = useUser();
    const { statusFilter, setStatusFilter, reset } = useStatusFilter();
    const [date, setDate] = useState([
        statusFilter.dateStartMoment
            ? statusFilter.dateStartMoment
            : moment().subtract(7, 'days'),
        statusFilter.dateEndMoment ? statusFilter.dateEndMoment : moment(),
    ]);
    // const [date, setDate] = useState([moment().subtract(7, 'days'), moment()]);
    const [loadingData, setLoadingData] = useState(false);
    const [dateRange, setDateRange] = useState(null);
    const [showLayout, setShowLayout] = useState(false);

    useEffect(() => {
        if (!statusFilter.dateStartMoment && !statusFilter.dateEndMoment) {
            const newDateReset = [moment().subtract(7, 'days'), moment()];
            setDate(newDateReset);
            filterDate(newDateReset, 'Emisión');
        }
    }, [statusFilter]);

    useEffect(() => {
        if (reloadOperations) {
            filterDate(date, 'Emisión');
            setReloadOperations(false);
        }
    }, [reloadOperations]);

    useEffect(() => {
        setShowLayout([ROL_LAYOUT].some((r) => person.roles.includes(r)));
    }, [person]);

    function filterDate(date, dateString) {
        setReloadOperations(true);

        if (date) {
            setLoadingData(true);
            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);

            let filters = '';
            if (dateString === 'Emisión') {
                filters = `?startDate=${date2.startDate}&endDate=${date2.endDate}`;
            }

            const data = {
                type: '',
                dateStartMoment: date[0],
                dateEndMoment: date[1],
            };
            setStatusFilter(data);

            getAllOperationsApi(token, filters).then((response) => {
                setLoadingData(false);

                // console.log(response, 'operations');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setOperations(response.result);
                    setReloadOperations(false);
                }
            });
        }
    }

    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop
                filterDate={filterDate}
                dateReceive={date}
                reset={reset}
                showLayout={showLayout}
            />

            <div className="billing__container">
                <OperationTable
                    operations={operations}
                    reloadOperations={reloadOperations}
                    loadingData={loadingData}
                    dateRange={dateRange}
                    setReloadOperations={setReloadOperations}
                />
            </div>
        </Layout>
    );
}

function HeaderTop({ filterDate, dateReceive, reset, showLayout }) {
    const { Title } = Typography;
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const { person } = useUser();

    const uploadExcel = () => {
        setModalIsVisible(true);
        setTitleModal('Subir Layout- Operaciones');
        setContextModal(
            <FileProducts setIsVisible={setModalIsVisible} type="operation" />
        );
    };

    const showModal = (type) => {
        if (type === 'addExcel') {
            uploadExcel();
        }
    };

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Title level={3}>Operaciones</Title>
                </Col>

                {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                    <Col xs={20} md={12} className="container-fecha">
                        <Row xs={10} md={12}>
                            <Button
                                onClick={() => showModal('addExcel')}
                                className="biller-btn"
                                icon={<FileExcelOutlined />}
                                style={{ marginBottom: 20 }}
                            >
                                Subir Excel
                            </Button>
                        </Row>
                    </Col>
                )}

                <Col
                    xs={20}
                    md={showLayout ? 24 : 12}
                    style={{
                        display: 'grid',
                        alignItems: 'end',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p style={{ marginRight: 5 }}>Fecha de Emisión:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Emisión'}
                            dateDisplay={dateReceive}
                        />
                        <Tooltip title="Limpiar rango de fecha">
                            <Button
                                type="primary"
                                danger
                                onClick={() => reset()}
                                // className="biller-btn"
                                icon={<DeleteOutlined />}
                                style={{ marginLeft: 20 }}
                            />
                        </Tooltip>
                    </Row>
                </Col>
                <Col
                    xs={20}
                    md={23}
                    style={{
                        display: 'grid',
                        alignItems: 'end',
                        justifyContent: 'end',
                    }}
                >
                    {/* <Row xs={10} md={12}>
                        <Button
                            type="primary"
                            danger
                            onClick={() => reset()}
                            // className="biller-btn"
                            icon={<DeleteOutlined />}
                            style={{ marginBottom: 20 }}
                        />
                    </Row> */}
                    {/* <Row xs={10} md={12}>
                        <p style={{ marginRight: 5 }}>Fecha de Vencimiento:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Vencimiento'}
                        />
                    </Row> */}
                </Col>
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                // width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
}
