import React, { useEffect, useState } from 'react';
import {
    Col,
    Input,
    Layout,
    Row,
    message,
    Typography,
    notification,
    Select
} from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import AccountBalance from '../../../components/Admin/AccountBalance';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import moment from 'moment';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { formatMoney, messageError } from '../../../utils/general';
import { getFinantialBalanceApi } from '../../../api/finantial';
import useUser from '../../../hooks/useUser';
import useStatusFilter from '../../../hooks/useStatusFilter';
import { getAllClients } from '../../../api/clients';
import { ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER } from '../../../utils/constants';

import './Balance.scss';

export default function Balance() {
    const [reloadBalance, setReloadBalance] = useState(true);
    const [balance, setBalance] = useState([]);
    const [total, setTotal] = useState(null);
    const token = getAccessTokenApi();
    const [dateRange, setDateRange] = useState(null);
    const [date, setDate] = useState([moment().subtract(7, 'days'), moment()]);
    const { statusFilter } = useStatusFilter();
    const [dateReceive, setDateReceive] = useState([]);
    const [titleFilter, setTitleFilter] = useState('');
    const [optionSelect, setOptionSelect] = useState(false);
    const [lastDate, setLastDate] = useState(null);
    const [client, setClient] = useState(null);

    useEffect(() => {
        if (reloadBalance) {
            filterDate(date, 'Emisión');
            setReloadBalance(false);
        }
    }, []);

    useEffect(() => {
        //filtar dependiendo el estatus de los amount
        const statusReceive = statusFilter.type;
        if (statusReceive != '') {
            // 'Vigente', 'Vencido', //TODO -> Status
            let dayToSearch = [moment().subtract(7, 'days'), moment()];
            if (statusFilter.dateStart != '' && statusFilter.dateEnd != '') {
                dayToSearch = [
                    moment(statusFilter.dateStart),
                    moment(statusFilter.dateEnd),
                ];
            }
            setDateReceive(dayToSearch);
            setOptionSelect(true)
            filterDate(dayToSearch, 'Emisión', statusFilter.type);
        }
    }, []);

    function filterDate(date, dateString, status, clientId) {
        // console.log(person,"person")
        setReloadBalance(true);
        // console.log(dateString, 'datestring');
        if (date) {
            setLastDate(date);
            // setFilteredInfo({});
            // setSortedInfo({});

            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);
            let filters = '';
            if (dateString === 'Emisión') {
                setTitleFilter('Emisión');
                filters = `&DocumentStartDate=${
                    date2.startDate
                }&DocumentEndDate=${date2.endDate}${
                    status !== '' && status !== undefined
                        ? status === 'Todos'
                            ? '&status=Vencido&status=Vigente'
                            : `&status=${status}`
                        : ''
                }${clientId ? '&customerId='+clientId : client ? '&customerId='+client :''}`;
            } else if (dateString === 'Vencimiento') {
                setTitleFilter('Vencimiento');
                filters = `&DueStartDate=${date2.startDate}&DueEndDate=${date2.endDate}`;
            }
            getFinantialBalanceApi(token, filters).then((response) => {
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    let totalBlance = 0;
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                        totalBlance += element.balance;
                    });
                    setBalance(response.result);
                    setTotal(totalBlance);
                    setReloadBalance(false);
                }
            });
        }
    }
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop
                filterDate={filterDate}
                total={total}
                dateReceive={dateReceive}
                optionSelect={optionSelect}
                lastDate={lastDate}
                setClient={setClient}
            />

            <div className="billing__container">
                <AccountBalance
                    balance={balance}
                    reloadBalance={reloadBalance}
                    setTotal={setTotal}
                    dateRange={dateRange}
                    total={total}
                    titleFilter={titleFilter}
                />
            </div>
        </Layout>
    );
}
function HeaderTop({ filterDate, total, dateReceive, optionSelect, lastDate, setClient }) {
    const { Title } = Typography;
    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };
    const { Option } = Select;
    const [valueSelect, setValueSelect] = useState(undefined);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [clients, setClients] = useState([]);
    const token = getAccessTokenApi();
    const { person } = useUser();

    useEffect(() => {
        getClients();
    }, [])

    const getClients = async () => {
        setIsLoadingUsers(true);
        const response = await getAllClients(token);
        
        if(response?.statusCode === 200){

            response.result.sort(function (a, b) {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            setClients(response.result);
            if(response.result.length > 0){
                const clientId = response.result[0].id;
                // setValueSelect(clientId);
                // setClient(clientId);
            }
        } else {
            if (response.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            }

            notification['error']({
                message: 'Inténtelomas tarde',
            });
        }
        
        setIsLoadingUsers(false);
    }

    function onChangeSelectUser(value) {
        setValueSelect(value);

        if (value === 'notFound') {
            if (clients.length > 0) {
                message.warn(
                    'No se han encontrado cliente'
                );
            }
        } else {
            if (value === undefined) {
                return;
            }
            //Buscar las facturas del cliente
            setClient(value);
            filterDate(lastDate, "Emisión", "", value);
            // FillInfoUser(value);
            
        }
    }

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col
                    xs={24}
                    md={12}
                    style={{ marginRight: 10 }}
                    className="header-top__left"
                >
                    <Row>
                        <Title level={3}>Estado de cuenta</Title>
                    </Row>
                </Col>
                <Col xs={20} md={11} className="container-fecha">
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p style={{ marginRight: 5 }}>Saldo total:</p>
                        <Input
                            style={{ width: 218 }}
                            disabled
                            xs={24}
                            value={total ? `$${formatMoney(total)}` : 0}
                        ></Input>
                    </Row>
                </Col>
                <Col xs={20} md={24} className="container-fecha">
                    <Row xs={24} md={14} style={{ marginBottom: 10 }}>
                        <p style={{ marginRight: 5 }}>Fecha de Emisión:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Emisión'}
                            dateDisplay={dateReceive}
                            optionselect={optionSelect ? 'otro' : false}
                        />
                    </Row>
                </Col>
                {/* <Col xs={20} md={23} className="container-fecha">
                    <Row xs={10} md={12}>
                        <p style={{ marginRight: 5 }}>Fecha de Vencimiento:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Vencimiento'}
                        />
                    </Row>
                </Col> */}
            </Row>


            {/* //Buscar Clientes */}
            {[
                ROL_LAYOUT,
                ROL_MANAGER,
                ROL_FINANCIAL,
                // ROL_OPERACIONES,
                // ROL_CUSTOMER,
            ].some((r) => person.roles.includes(r)) && (
            
                <Row>
                    {/* <Col span={12}>
                        <p style={{marginRight: 10}}>Cliente</p>

                    </Col> */}
                    
                    <Col xs={24} md={24} className="display-end">
                        
                        <div style={{width: 350}}>

                        <Select
                            // allowClear
                            disabled={isLoadingUsers}
                            showSearch
                            style={{ width: '100%'}}
                            placeholder="Busque a un cliente"
                            optionFilterProp="children"
                            onChange={onChangeSelectUser}
                            value={valueSelect}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                                {clients.map((user) => {
                                    return (
                                        <Option
                                            key={user.id}
                                            value={user.id}
                                        >
                                            {user.name}
                                        </Option>
                                    );
                                })}
                        </Select>
                        </div>
                    </Col>
            </Row>)}
        </div>
    );
}
