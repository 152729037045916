import React, { useState, useEffect } from 'react';
import { Row, Col, notification, Typography } from 'antd';
import { DownOutlined, HistoryOutlined } from '@ant-design/icons';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import './ChartAmount.scss';
import DatePickerOptions from '../../General/DatePickerOptions/DatePickerOptions';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getOperationsByMonthApi } from '../../../api/operations';
import { formatMoney, messageError } from '../../../utils/general';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default function ChartAmount() {
    // const [optionSelected, setOptionSelected] = useState('1');
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);
    const [fisrtTime, setFisrtTime] = useState(true);
    const token = getAccessTokenApi();
    const { Title } = Typography;
    const dataChart = [
        {
            name: 'Recibido',
            uv: 12,
            pv: 12,
            amt: 30,
        },
        {
            name: 'Embarque',
            uv: 19,
            pv: 19,
            amt: 29,
        },
    ];

    const dateString  = [
        moment().startOf('month').subtract(2, 'month'),
        moment().endOf('month').subtract(0, 'month'),
    ];
    
    useEffect(() => {
        const startDate = moment().startOf('month').subtract(2, 'month');
        // const now = moment();
        
        const endDate = moment().endOf('month').subtract(0, 'month');
        // const lastWeek = moment().subtract(7, 'days');

        // filterDate([now, lastWeek]);

        
        filterDate([startDate, endDate], dateString);
    }, []);

    const colors = scaleOrdinal(schemeCategory10).range();

    // const menu = (
    //     <Menu
    //         className="chart-amount-select"
    //         defaultSelectedKeys={[optionSelected]}
    //         selectedKeys={optionSelected}
    //     >
    //         <Menu.Item
    //             key="1"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('1')}
    //         >
    //             Últimos 7 días
    //         </Menu.Item>
    //         <Menu.Item
    //             key="2"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('2')}
    //         >
    //             Mes actual
    //         </Menu.Item>
    //         <Menu.Item
    //             key="3"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('3')}
    //         >
    //             Mes anterior
    //         </Menu.Item>
    //         <Menu.Item
    //             key="4"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('4')}
    //         >
    //             Últimos 3 meses
    //         </Menu.Item>
    //         <Menu.Item
    //             key="5"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('5')}
    //         >
    //             Últimos 6 meses
    //         </Menu.Item>
    //         <Menu.Item
    //             key="6"
    //             icon={<HistoryOutlined />}
    //             onClick={() => handleChangeDate('6')}
    //         >
    //             Últimos 12 meses
    //         </Menu.Item>
    //         <Divider>Otro rango</Divider>
    //         <div className="container-rangepicker">
    //             <RangePicker onChange={onChange} />
    //         </div>
    //     </Menu>
    // );

    // function onChange(value, dateString) {
    //     if (value) {
    //         setDateSelected({
    //             dateStart: value && value[0],
    //             dateEnd: value && value[1],
    //             dateString: `${value[0].format(
    //                 'DD/MM/YYYY'
    //             )} - ${value[1].format('DD/MM/YYYY')}`,
    //         });
    //         setOptionSelected('');
    //     }
    // }

    // const handleChangeDate = (type) => {
    //     const now = moment();
    //     let dateStart = moment();
    //     let dateEnd = moment().subtract(7, 'days');
    //     let dateString = `${dateStart.format('DD/MM/YYYY')} - ${dateEnd.format(
    //         'DD/MM/YYYY'
    //     )}`;

    //     switch (type) {
    //         // last-7-days
    //         case '1':
    //             dateStart = moment();
    //             dateEnd = moment().subtract(7, 'days');
    //             dateString = `${moment().format('DD/MM/YYYY')} - ${moment()
    //                 .subtract(7, 'days')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         // currentMonth
    //         case '2':
    //             dateStart = moment().startOf('month');
    //             dateEnd = moment().endOf('month');
    //             dateString = `${moment()
    //                 .startOf('month')
    //                 .format('DD/MM/YYYY')} - ${moment()
    //                 .endOf('month')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         // lastmonth
    //         case '3':
    //             dateStart = moment().startOf('month').subtract(1, 'month');
    //             dateEnd = moment().endOf('month').subtract(1, 'month');
    //             dateString = `${moment()
    //                 .startOf('month')
    //                 .subtract(1, 'month')
    //                 .format('DD/MM/YYYY')} - ${moment()
    //                 .endOf('month')
    //                 .subtract(1, 'month')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         // last3month
    //         case '4':
    //             dateStart = moment().startOf('month').subtract(3, 'month');
    //             dateEnd = moment().endOf('month').subtract(1, 'month');
    //             dateString = `${moment()
    //                 .startOf('month')
    //                 .subtract(3, 'month')
    //                 .format('DD/MM/YYYY')} - ${moment()
    //                 .endOf('month')
    //                 .subtract(1, 'month')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         case '5':
    //             dateStart = moment().startOf('month').subtract(6, 'month');
    //             dateEnd = moment().endOf('month').subtract(1, 'month');
    //             dateString = `${moment()
    //                 .startOf('month')
    //                 .subtract(6, 'month')
    //                 .format('DD/MM/YYYY')} - ${moment()
    //                 .endOf('month')
    //                 .subtract(1, 'month')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         case '6':
    //             dateStart = moment().startOf('month').subtract(12, 'month');
    //             dateEnd = moment().endOf('month').subtract(1, 'month');
    //             dateString = `${moment()
    //                 .startOf('month')
    //                 .subtract(12, 'month')
    //                 .format('DD/MM/YYYY')} - ${moment()
    //                 .endOf('month')
    //                 .subtract(1, 'month')
    //                 .format('DD/MM/YYYY')}`;
    //             break;

    //         default:
    //             break;
    //     }

    //     setDateSelected({
    //         dateStart,
    //         dateEnd,
    //         dateString,
    //     });
    //     setOptionSelected(type);
    // };

    function filterDate(date, dateString) {
        console.log(date, 'date');
        if (date) {
            // setFilteredInfo({});
            // setSortedInfo({});

            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let filters = '';
            filters = `?startDate=${date2.startDate}&endDate=${date2.endDate}`;

            setLoadingData(true);
            // console.log(payload, "payload");
            getOperationsByMonthApi(token, filters).then((response) => {
                setLoadingData(false);
                // console.log(response, 'bills');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    // console.log(response);
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                        element['Cantidad'] = element.count;
                    });
                    setData(response.result);
                    setFisrtTime(false);
                    // setReloadBills(false);
                }
            });
        }
    }

    return (
        <>
            <Row className="chart-amount">
                <Col span={24} className="chart-amount__title">
                    <Title level={3}>Resumen de operaciones</Title>
                </Col>
                <Col span={24} className="chart-amount__dropdown">
                    <DatePickerOptions
                        filterDate={filterDate}
                        typeDoc={'Emisión'}
                        dateDisplay={fisrtTime ? dateString : false}
                        optionselect={'4'}
                    />
                    {/* <Dropdown overlay={menu} arrow={true} trigger={['click']}>
                        <Button>
                            {dateSelected.dateString} <DownOutlined />
                        </Button>
                    </Dropdown> */}
                </Col>
                <Col span={24}>
                    <ResponsiveContainer width="100%" height={450}>
                        <BarChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* <XAxis
                                dataKey="month"
                                padding={{ left: 30, right: 30 }}
                                tick={<CustomizedAxisTick />}
                            />
                            <YAxis
                                padding={{ top: 30 }}
                                tickFormatter={(value) => {
                                    return value;
                                }}
                            /> */}

                            <XAxis dataKey="dispDate" />
                            <YAxis
                                padding={{ top: 30 }}
                                // tickFormatter={(value) => {
                                //     return `$ ${formatMoney(value)}`;
                                // }}
                                width={200}
                            />
                            {/* <Legend
                                verticalAlign="top"
                                iconSize={30}
                                iconType="rect"
                            /> */}

                            {/* <YAxis yAxisId="left" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Ventas", angle: -90, position: 'insideLeft', offset: -5}} /> */}
                            {/* <YAxis yAxisId="right" orientation="right" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Canceladas", angle: 90, position: 'insideRight', offset: -20 }} />   */}
                            {/* <Tooltip /> */}
                            {/* <Legend /> */}
                            <Tooltip
                                formatter={(value, name, props) => {
                                    // console.log(value, name);
                                    return '' + value;
                                }}
                                // contentStyle={{
                                //     backgroundColor: '#000',
                                //     color: '#fff',
                                // }}
                            />

                            <Bar
                                dataKey="Cantidad"
                                fill="#000000"
                                label={{ position: 'top' }}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={colors[index % 20]}
                                    />
                                ))}
                            </Bar>
                            {/* <Bar dataKey="count" fill="#95e1ff" name="Octubre" /> */}

                            {/* <Bar dataKey="uv" fill="#fff682" name="Noviembre" />
                            <Bar
                                dataKey="amt"
                                fill="#d3f996"
                                name="Diciembre"
                            /> */}

                            {/* <Tooltip formatter={(value, name, props)=>{return '$ '+formatMoney(value)}} /> */}
                            {/* <Bar
                                type="monotone"
                                dataKey="uv"
                                stroke="#82ca9d"
                                activeDot={{ r: 8 }}
                                strokeWidth={1.5}
                                label={<CustomizedLabel />}
                            />
                            <Bar
                                type="monotone"
                                dataKey="pv"
                                stroke="#d9363e"
                                strokeWidth={1}
                            /> */}

                            {/* <Line yAxisId="left" type="monotone" dataKey="sales" stroke="#82ca9d" activeDot={{ r: 8 }} strokeWidth={1.5} label={<CustomizedLabel />}/>
    <Line yAxisId="right" type="monotone" dataKey="cancelled" stroke="#d9363e" strokeWidth={1} /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </>
    );
}

function CustomizedAxisTick(props) {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
}

function CustomizedLabel(props) {
    const { x, y, stroke, value } = props;

    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill={stroke}
            fontSize={12}
            textAnchor="middle"
        >
            {value}
        </text>
    );
}
