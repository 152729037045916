import { basePath } from './config';

export function getSectionsApi(token) {
    const url = `${basePath}/ApplicationSections/GetApplicationSections?portal=0`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

export function getSectionsContactsApi(token, customerId) {
    const url = `${basePath}/ApplicationSections/GetApplicationSectionContacts?portal=0&customerId=${customerId}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function AsignSectionContactApi(token, data) {
    const url = `${basePath}/ApplicationSections/AsignApplicationSectionContact`;
    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//get contacto customer
export function getContactBySectionApi(token, role) {
    const url = `${basePath}/Account/Contact?role=${role}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//get contacto admin
export function getCustomerContactApi(token, idCustomer) {
    const url = `${basePath}/Customer/${idCustomer}/Contacts`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
