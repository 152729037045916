import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import { getCustomerContactApi } from '../../../../api/sections';

export default function Contact({ idCustomer }) {
    const [reloadContacts, setReloadContacts] = useState(true);
    const [users, setUsers] = useState([]);
    const token = getAccessTokenApi();
    const sections = [
        { name: 'Estado de cuenta', key: 0 },
        { name: 'Facturación', key: 1 },
    ];

    useEffect(() => {
        setReloadContacts(true);

        getCustomerContactApi(token, idCustomer)
            .then((response) => {
                if (response?.statusCode === 200) {
                    setUsers(response.result);
                } else {
                    setUsers([]);
                }
            })
            .finally(() => setReloadContacts(false));
    }, [token, idCustomer]);

    const columns = [
        {
            title: 'Secciones',
            dataIndex: 'role',
            key: 'role',
            render: (role) => (
                <>
                    {' '}
                    {role === 'Financial' && (
                        <>
                            {sections.map((s, index) => (
                                <Tag
                                    key={index}
                                    color={s.key === 0 ? 'blue' : 'green'}
                                >
                                    {s.name}
                                </Tag>
                            ))}
                        </>
                    )}
                    {role === 'Operaciones' && (
                        <Tag color={'red'}>Operaciones</Tag>
                    )}
                </>
            ),
        },
        {
            title: 'Nombre',
            dataIndex: 'fullName',
            key: 'fullName',
        },

        {
            title: 'Correo',
            dataIndex: 'email',
            key: '4',
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={users}
                scroll={{ x: 500 }}
                loading={reloadContacts}
                // pagination={{ defaultPageSize: 5 }}
            />
        </>
    );
}
